import React from 'react'
import { Link } from 'react-router-dom'
import Drawal from './Drawal'
import Topbar from './Topbar'

const Header = () => {
    return (
        <>
            <Topbar />
            <header class="header" id="header">
                <nav class="navbar container">
                    <Link to="/" class="brand"><img src="../assets/images/logo.svg" alt="" /></Link>
               <Drawal/>
                    <div class="menu" id="menu">
                        <ul class="menu-inner">

                            <li class="menu-item"><Link to="/" class="menu-link">Home</Link></li>
                            <li class="menu-item"><Link to="/about" class="menu-link">About</Link></li>
                            <li class="menu-item"><Link to="/service" class="menu-link">Services & applications</Link></li>
                            <li class="menu-item"><Link to="/portfolio" class="menu-link">Portfolio</Link></li>
                            <li class="menu-item"><Link to="/blog" class="menu-link">blogs</Link></li>
                            <li class="menu-item"><Link to="/contact-us" class="menu-link">Contact us</Link></li>
                        </ul>
                    </div>
                    <a target='_blank' href="https://pms.crocentinternational.com/index.php/signin" class="menu-block">Login</a>
                </nav>
            </header>
        </>
    )
}

export default Header