import React from "react";
import "./footer.css";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { FaFacebook, FaFacebookF, FaInstagram, FaLinkedin, FaLinkedinIn, FaLocationArrow, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
const Footer = () => {
  return (
    <div className="footer-dark-bg">
      <div className="footer_perticle">
        <img src="assets/vector/footer.png" alt="" className="" />
      </div>

      <footer className="row   py-5  container">
        <div className="col-md-4">
          <Fade bottom>
            <a
              href="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img src="../assets/images/white-logo.svg" alt="" />
            </a>
            <p className="text-footer_brand">
              We are one of the leading IT industries delivering professional
              web services. Our purpose is to imprint value to a brand and long
              term business growth. We do this by making a difference by taking
              steps as required from time to time.
            </p>


            <ul className="footer_social_icon">
                <li><a href=""><FaFacebookF/></a></li>
                <li><a href=""><FaTwitter/></a></li>
                <li><a href=""><FaLinkedinIn/></a></li>
                <li><a href=""><FaInstagram/></a></li>
            </ul>
          </Fade>
        </div>

        <div className="col mb-4"></div>

        <div className="col-md-3">
          <Fade bottom>
            <h5 className="footer_hedding">
              <span className="text_yellow">Quick</span> Link
            </h5>
            <div className="row">
              <div className="col-6">
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link to="/" className="nav-link p-0 text-muted">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/about" className="nav-link p-0 text-muted">
                      About
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/web-design" className="nav-link p-0 text-muted">
                      Web Design
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/" className="nav-link p-0 text-muted">
                      Work
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link to="/blog" className="nav-link p-0 text-muted">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/our-team" className="nav-link p-0 text-muted">
                      Team
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/testimonials"
                      className="nav-link p-0 text-muted"
                    >
                      Testimonial
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/contact-us" className="nav-link p-0 text-muted">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>

        <div className="col mb-3"></div>

        <div className="col-md-4">
          <Fade bottom>
            <h5 className="footer_hedding">
              <span className="text_yellow">Contact</span> US
            </h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2 footer_icoin_grp">
                <FaLocationArrow className="icoinsfooterr" />{" "}
                <p className="footer_list">
                  Office No - 101, Plot No: E-299, Industrial Area Phase 8A ,
                  Sector 75, SAS Nagar, Punjab 160075
                </p>
              </li>
              <li className="nav-item mb-2 footer_icoin_grp">
                <AiOutlineMail className="icoinsfooterr" />{" "}
                <p className="footer_list">work@crocentinternational.com</p>
              </li>
              <li className="nav-item mb-2 footer_icoin_grp">
                <FiPhoneCall className="icoinsfooterr" />
                <p className="footer_list">+91 98887 47538</p>
              </li>
            </ul>
          </Fade>
        </div>
        <Fade bottom>
          <hr className="footer_hr" />
          <p className="footer_copy">
            Crocent International © 2022. All Rights Reserved.
          </p>
        </Fade>
      </footer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="fotter_bacround"
        width="100%"
        height="698"
        viewBox="0 0 100% 698"
        fill="none"
      >
        <path
          d="M332.5 95.4107C206 48.9107 64.2896 -0.872497 0 0.0116062V697.911H1920V30.0116C1811.58 21.5239 1674.43 64.1971 1619.4 86.5946C1469.97 138.226 1315.04 108.108 1256.25 86.5946C1054 10.9153 844.742 40.9159 765.394 65.3761C566.617 133.275 393.604 113.093 332.5 95.4107Z"
          fill="#01181E"
        />
      </svg>
    </div>
  );
};

export default Footer;
