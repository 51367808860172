import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Collapse } from 'antd';
import { useState } from 'react';
import { BiArrowToTop, BiDownArrow, BiRightArrow, BiSolidDownArrow } from 'react-icons/bi';

const Faq = () => {
    const { Panel } = Collapse;
    const customExpandIcon = ({ isActive }) =>
    isActive ? <BiDownArrow /> : <BiRightArrow />;
    
    return (
        <section className="faq_section">
            <div className="faq_vector">
                <img src="assets/vector/faq_vector.png" className='' alt="" />
            </div>
            <div className="container">
                <div className="faq_body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="faq_conatain_body">
                                <h5 className="up_tagline">Your Answer--</h5>
                                <h2>Frequently Asked Questions</h2>
                                <p>Find answers and solutions to IT's most frequently asked questions (FAQs). If you can't find an answer, contact us and we will be happy to help.</p>
                                <a href="" className='faq_button btn_text'> <span className='cercle_left'><ArrowForwardIcon /></span>Get In Touch</a>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <Collapse accordion bordered={false} colorBgContainer="#fff" expandIconPosition="right"
                             defaultActiveKey={1}
                             expandIcon={customExpandIcon}
                            >
                                <Panel header="Do you provide ongoing support and maintenance?" key="1">

                                    <p>Absolutely! We understand the importance of reliable support and maintenance for your IT systems. Our dedicated team is available to provide comprehensive support, including troubleshooting, system updates, and proactive monitoring.</p>

                                </Panel>
                                <Panel header="How do you ensure data security and confidentiality?" key="2">

                                    <p>We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure , they cannot foresee lipsum.</p>

                                </Panel>
                                <Panel header="Do you offer customized solutions?" key="3">

                                    <p>We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure , they cannot foresee lipsum.</p>

                                </Panel>
                                <Panel header="Can you assist with technology planning and implementation?" key="4">

                                    <p>We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure , they cannot foresee lipsum.</p>

                                </Panel>

                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq