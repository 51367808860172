import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Shimmer } from "react-shimmer";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Blog = () => {
  const [data, setData] = useState(null);
  //   React.useLayoutEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);
  useEffect(() => {
    document.title = "Blogs - Crocent International";
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.crocentinternational.com/blog/BlogWeb/crocentwebservices.com"
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />

      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />
          <img
            class="banner-image2"
            src="assets/vector/blog.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2"> Blogs</h1>
            <p class="paragraph2">We can grow your business</p>
          </div>
        </div>
      </section>
      <main>
        <section className="blogs_body">
          <div className="container">
            <div className="review_heading">
              <h5 className="up_tagline">-- Our Blog --</h5>
              <h4>Latest News & Articles Directly from Blog</h4>
              <p>
                Get the latest tips and tricks in technology and marketing to
                learn the best practices you can put into action. 
              </p>
            </div>

            <div className="blg_body">
              {data == null ? (
                <div className="row mb-5">
                  <div className="col-md-6">
                    <Fade top>
                      <div className="article-blog2">
                        <div className="shimmer-placeholder">
                          <Shimmer width={"100%"} height={300} />
                        </div>
                        <div className="article_footer shadow-sm">
                          <Shimmer width={"50%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <a href="" className="read_more">
                            <Shimmer width={"20%"} height={16} />
                          </a>
                          <br />
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade top>
                      <div className="article-blog2">
                        <div className="shimmer-placeholder">
                          <Shimmer width={"100%"} height={300} />
                        </div>
                        <div className="article_footer shadow-sm">
                          <Shimmer width={"50%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <a href="" className="read_more">
                            <Shimmer width={"20%"} height={16} />
                          </a>
                          <br />
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {data.map((item, index) => (
                    <div className="col-md-6">
                      <div className="blog_card2">
                        {/* <img src="assets/blogs/1.png" alt="" /> */}

                        <LazyLoadImage
                          src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                          width={"100%"}
                          height={"100%"}
                          alt="Image Alt"
                          effect="blur"
                        />

                        <div className="contain">
                          <h6>{item.post_date}</h6>
                          <Link to={`blog/${item.url}`}>
                            <h3>{item.blog_title}</h3>
                          </Link>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                          ></p>
                          <Link to={`blog/${item.url}`} className="btn_text">
                            {" "}
                            <span className="cercle_left">
                              <ArrowForwardIcon />
                            </span>{" "}
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="review_more">
                <a href="" className="btn_text">
                  {" "}
                  <span className="cercle_left">
                    <ArrowForwardIcon />
                  </span>{" "}
                  View More
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Blog;
