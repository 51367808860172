import React from 'react'
import Carousel from 'react-multi-carousel';
const CompaniLogo = () => {
    const responsive3 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    return (
        <Carousel responsive={responsive3}>
            <img src="assets/company/5.png" alt="" />
            <img src="assets/company/3.png" alt="" />
            <img src="assets/company/4.png" alt="" />
            <img src="assets/company/2.png" alt="" />
            <img src="assets/company/1.png" alt="" />
        </Carousel>
    )
}

export default CompaniLogo