import React, { useState } from 'react'
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const Drawal = () => {

    const [isDrawerOen, setDrawerOen] = useState(false);
    return (
        <>


            <div className="burger" onClick={() => setDrawerOen(true)}>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
            </div>





            <Drawer
                anchor="left"
                open={isDrawerOen}
                onClose={() => setDrawerOen(false)}>

                <Box
                    sx={{ width: 250 }}
                    role="presentation"

                >
                    <List>
                        <Link to="/" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="Home" />
                            </ListItemButton></ListItem>
                        </Link>

                        <Link to="/about" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="About" />
                            </ListItemButton></ListItem>
                        </Link>
                        <Link to="/Service" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="Services & applications" />
                            </ListItemButton></ListItem>
                        </Link>
                        <Link to="/portfolio" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="Portfolio" />
                            </ListItemButton></ListItem>
                        </Link>
                        <Link to="/blog" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="Blogs" />
                            </ListItemButton></ListItem>
                        </Link>
                        <Link to="/contact-us" className='drawer_link'>
                            <ListItem disablePadding><ListItemButton>
                                <ListItemText primary="Contact Us" />
                            </ListItemButton></ListItem>
                        </Link>
                    </List></Box></Drawer></>
    )
}

export default Drawal