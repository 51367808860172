import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Faq from "../common/Faq";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import Gettouch from "../common/Gettouch";
import Shimmer from "react-shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Fade } from "react-reveal";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { BsEye } from "react-icons/bs";
const BlogView = () => {
  const [data, setData] = useState(null);
  const [recent, setRecent] = useState(null);
  const { url } = useParams();
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const setRecents = async () => {
    try {
      const response = await axios.get("https://admin.crocentinternational.com/blog/recent_blog/crocentwebservices.com");
      setRecent(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.crocentinternational.com/blog/BlogWebUrl/" + url);
        setData(response.data);
        console.log(response.data);
        document.title = response.data.meta_desc;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    setRecents();
    fetchData();
  }, [url]);

  return (
    <>
      <Header />
      <Fade bottom>
        <section class=" banner banner-section">
          <div class="container banner-column">
            {/* <img
              class="side_line"
              src="../assets/vector/sideline.png"
              alt="banner"
            /> */}

            <img
              class="banner-image2"
              src="../assets/vector/all_block.png"
              alt="banner"
            />

            <img
              src="../assets/vector/egg.png"
              alt=""
              className="bacraound_secle"
            />
            <div class="banner-inner">
              <h1 class="heading-xl2">Blogs</h1>
              <p class="paragraph2">We can grow your business</p>
            </div>
          </div>
        </section>
      </Fade>
      <main>
      
      <section className="main_body">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                {data ? (
                  <div className="blog_body">
               
                    <div className="images">
                      <Shimmer>
                        <LazyLoadImage
                          src={`https://admin.crocentinternational.com/assets/upload/images/${data.blog_image}`}
                          alt="Image Alt"
                          width={"100%"}
                          effect="blur"
                        />
                      </Shimmer>
                    </div>

                    <div className="blog_head">
                      <p>
                        {data.post_date}{" "}
                        <span className="border_diveider">|</span>{" "}
                        <span>
                          <BsEye className="BsEye" />
                          {data.views}
                        </span>
                      </p>
                      <h2>{data.blog_title}</h2>
                    </div>

                    <div
                      className="containt"
                      dangerouslySetInnerHTML={{ __html: data.desc }}
                    ></div>
                  </div>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
              <div className="col-md-4">
                <div className="blog_side_bar">
                  {recent ? (
                    <div className="blog_recent_bar">
                      <div className="recent_head">
                        <h4>
                          Recent<span> Blog</span>
                        </h4>
                      </div>
                      {recent.map((item, index) => (
                        <Link to={`${item.url}`} className="recent_item">
                          <div className="img">
                            <Shimmer>
                              <LazyLoadImage
                                src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                                alt="Image Alt"
                                width={"100%"}
                                effect="blur"
                              />
                            </Shimmer>
                          </div>
                          <div className="recent_hedding">
                            <h4>{item.blog_title}</h4>
                            <p><span className="lern_more_arrow"> <ArrowForwardIcon/></span> Read More</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}

             
                </div>
              </div>
            </div>
          </div>
        </section>
     

 



      </main>
      <Footer />
    </>
  );
};

export default BlogView;
