import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Faq from "../common/Faq";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { BsArrowRight } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Gettouch from "../common/Gettouch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./ProgressProvider";
import { Margin } from "@mui/icons-material";
import reviewsData from "../DataRatting.json";
import { FaAddressCard } from "react-icons/fa";
const Portfolio = () => {
  const value = 89;
  const value2 = 76;
  const value3 = 63;
  return (
    <>
      <Header />
      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />
          <img
            class="banner-image2"
            src="assets/vector/portfolio.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2">Portfolio</h1>
            <p class="paragraph2">We can grow your business</p>
          </div>
        </div>
      </section>
      <main>
        <section className="PortFolio_main">
          <div className="container">
          <div className="narkettinf_c_heading">
              <Fade bottom>
                <h5 className="up_tagline">
                  <span className="treeeLine"></span>Projects{" "}
                  <span className="treeeLine"></span>
                </h5>
              </Fade>
              <Fade bottom>
                <h4>Take a Look Our Projects</h4>
              </Fade>
              <Fade bottom>
                <p>
                We are thrilled to present a curated collection of our latest endeavors that reflect our passion, expertise, and commitment to excellence. 
                </p>
              </Fade>
            </div>
            <div className="PortFolio_boc">
            <div className="row">
                <div className="col-md-4">
                <div className="mark_dashboard">
                    <img src="assets/port/1port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
                <div className="col-md-4"> 
                <div className="mark_dashboard">
                    <img src="assets/port/2port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="mark_dashboard">
                    <img src="assets/port/3port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="mark_dashboard">
                    <img src="assets/port/4port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="mark_dashboard">
                    <img src="assets/port/5port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="mark_dashboard">
                    <img src="assets/port/6port.png" alt="" />
                    <div className="main-over-lay"></div>
                    <div className="btn-arrow-hover">
                      <BsArrowRight/>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </section>

      
   


        <section className="marketing_features">
          <div className="blogbackround2">
            <img src="assets/vector/degital_marketingbg.png" alt="" />
          </div>
          <div className="container">
          <div className="narkettinf_c_heading">
              <Fade bottom>
                <h5 className="up_tagline">
                  <span className="treeeLine"></span> Digital Marleting{" "}
                  <span className="treeeLine"></span>
                </h5>
              </Fade>
              <Fade bottom>
                <h4>digital marketing portfolio</h4>
              </Fade>
              <Fade bottom>
                <p>
                When we combine creativity, strategy, and tech; the results look good. Take a look at the amazing clients we’ve worked with.
                </p>
              </Fade>
            </div>

            <div className="degital_marketing_dashboard">
              <div className="row">
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard1.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard2.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard3.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="testimonial_body">
          <div className="container">
            <div className="review_heading">
              <h5 className="up_tagline">-- TESTIMONIALS --</h5>
              <h4>WHAT OUR CLIENT’S SAY ABOUT US</h4>
              <p>
                Check out our clients testimonials to hear directly from the
                marketers and business owners who’ve worked with us.
              </p>
            </div>

            <div className="review_bo
            dy">
              <div className="row">
                {reviewsData.slice(0, 4).map((review, index) => (
                  <div key={index} className="col-md-6">
                  <div className="top_review_top2">
                    <div className="top_review_box2">
                      <div className="head">
                        <img src={review.images} alt="" />
                        <div className="div">
                          <h2>{review.name}</h2>
                          {/* <a href="">{review.role}</a> */}
                        </div>
                      </div>
                      <p>{review.content}</p>
                    </div>
                  </div>
                  </div>
                ))}

              </div>


              <div className="review_more">
                <a href="" className="btn_text">
                  {" "}
                  <span className="cercle_left">
                    <ArrowForwardIcon />
                  </span>{" "}
                  View More
                </a>
              </div>
            </div>
          </div>
        </section>

        <Faq />
      </main>
      <Footer />
    </>
  );
};

export default Portfolio;
