import React from 'react'
import { BrowserRouter as Router,  Route } from 'react-router-dom';
import About from './compnents/pages/About';
import Blog from './compnents/pages/Blog';
import Contact from './compnents/pages/Contact';
import Home from './compnents/pages/Home';
import Service from './compnents/pages/Service';
import Team from './compnents/pages/Team';
import { Testimonials } from './compnents/pages/Testimonials';
import WebDesign from './compnents/pages/WebDesign';
import ApplicationDevelopment from './compnents/pages/ApplicationDevelopment';
import GraphicDesign from './compnents/pages/GraphicDesign';
import CMSDevelopment from './compnents/pages/CMSDevelopment';
import MaintenanceSupport from './compnents/pages/MaintenanceSupport';
import WebDevelopment from './compnents/pages/WebDevelopment';
import DigitalMarketing from './compnents/pages/DigitalMarketing';
import Portfolio from './compnents/pages/Portfolio';
import BlogView from './compnents/pages/BlogView';
const App = () => {
  return (
    <>
        <Router>
        <Route exact path='/' component={Home} />
        <Route exact path='/testimonials' component={Testimonials} />
        <Route exact path='/contact-us' component={Contact} />
        <Route exact path='/about' component={About} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/blog/:url' component={BlogView} />
        <Route exact path='/portfolio' component={Portfolio} />
        <Route exact path='/our-team' component={Team} />
        <Route exact path='/service' component={Service} />
        <Route exact path='/graphic-design' component={GraphicDesign} />
        <Route exact path='/cms-development' component={CMSDevelopment} />
        <Route exact path='/maintenance-and-support' component={MaintenanceSupport} />
        <Route exact path='/application-development' component={ApplicationDevelopment} />
        <Route exact path='/web-development' component={WebDevelopment} />
        <Route exact path='/web-design' component={WebDesign} />
        <Route exact path='/digital-marketing' component={DigitalMarketing} />
    </Router>
    </>
  )
}

export default App