import React from 'react'
import '../Style/main.css';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Gettouch from '../common/Gettouch';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const Service = () => {
    return (
        <>
            <Header />

            <section class=" banner banner-section">
                <div class="container banner-column">
                    <img class="side_line" src="assets/vector/sideline.png" alt="banner" />
                    <img class="banner-image2" src="assets/vector/service.png" alt="banner" />
                    <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
                    <div class="banner-inner">
                        <h1 class="heading-xl2"> Services</h1>
                        <p class="paragraph2">We can grow your business</p>

                    </div>
                </div>
            </section>
            <main>
                <section className="blogs_body">
                    <div className="container">
                        <div className="review_heading">
                            <h5 className="up_tagline">-- Services --</h5>
                            <h4>We provide IT & Business solutions</h4>
                            <p>We look forward to completing the business requirements with our cost-effective solutions and resources that they may not be able to afford on their own.</p>
                        </div>


                        <div className="service_body">
                            <div className="row">
                                <Link to="/web-design" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#FFF6EE" />
                                        </svg>

                                        <img src="assets/vector/web.png" alt="" />
                                        <div className="body_bus">
                                            <h5>Web Design</h5>
                                            <p>Our agile and cross functioning teams build, design, create, and maintain mobile applications...</p>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/application-development" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#F0F9FF" />
                                        </svg>

                                        <img src="assets/vector/application.png" alt="" />
                                        <div className="body_bus">
                                            <h5>APPLICATIONS DEVELOPMENT</h5>
                                            <p>Our agile and cross functioning teams build, design, create, and maintain mobile applications for various platforms such as iOS and Android.</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/digital-marketing" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#F5F0F7" />
                                        </svg>

                                        <img src="assets/vector/marketing.png" alt="" />
                                        <div className="body_bus">
                                            <h5>DIGITAL MARKETING</h5>
                                            <p>Online marketing to meet the needs and goals of your customers and get the best results as lead generation, and sales.</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/web-design" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#F5F0F7" />
                                        </svg>

                                        <img src="assets/vector/hosting_logo.png" alt="" />
                                        <div className="body_bus">
                                            <h5>HOSTING</h5>
                                            <p>Web hosting provider to host websites and make them accessible over the internet.</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/graphic-design" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#FFE4E4" />
                                        </svg>

                                        <img src="assets/vector/graphic.png" alt="" />
                                        <div className="body_bus">
                                            <h5>Graphic Design</h5>
                                            <p>Creating eye-catching logos, websites, marketing collateral, and other visual assets that effectively communicate your brand's message and identity. </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/web-development" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#F0EAFF" />
                                        </svg>

                                        <img src="assets/vector/web_devloments.png" alt="" />
                                        <div className="body_bus">
                                            <h5>web DEVELOPMENT</h5>
                                            <p>From e-commerce platforms to custom web applications, we develop solutions that meet the specific needs and requirements of each client. </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/cms-development" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#D3F3FF" />
                                        </svg>

                                        <img src="assets/vector/cms.png" alt="" />
                                        <div className="body_bus">
                                            <h5>CMS Development</h5>
                                            <p>CMS Development to manage website content and data by providing an intuitive user interface.</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/maintenance-and-support" className="col-md-3">
                                    <div className="cart_Busness2">
                                        <svg className='rolls' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                                            <path d="M55.144 81.7567C45.8653 81.834 37.2731 78.9153 28.8527 75.5931C23.5619 73.4575 18.4053 71.0036 13.411 68.2449C6.32956 64.3819 1.78029 58.5101 0.458429 50.4064C-0.180878 45.9149 0.605725 41.3359 2.70731 37.3152C8.95126 24.2683 18.8673 13.3279 31.2389 5.83615C35.0844 3.43251 39.0671 1.26065 43.5391 0.436547C52.2943 -1.18591 59.5817 1.76713 65.264 8.41147C70.2854 14.2832 73.2982 21.3739 75.9247 28.5248C78.1467 34.4612 79.8975 40.5636 81.1607 46.7752C82.7057 54.7244 82.3452 62.6993 78.1565 70.0218C74.4741 76.4687 68.7146 79.8682 61.5473 81.1816C59.4358 81.5507 57.2556 81.5507 55.144 81.7567Z" fill="#FFEDFD" />
                                        </svg>

                                        <img src="assets/vector/maint_support.png" alt="" />
                                        <div className="body_bus">
                                            <h5>Maintenance & Support</h5>
                                            <p>Manage all aspects of website maintenance and support, from routine updates to complex technical issues.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>



                    </div>
                </section>






                <section className="eliminate">
                <div className="eliminatebg">
                            <img src="assets/vector/eliminatebg.png" alt="" />
                        </div>
                    <div className="container">
                    
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mage_group">
                                    <img src="assets/trans/tp.png" alt="" className="pert_top" />
                                    <img src="assets/trans/bt.png" alt="" className="pert_btm" />
                                    <img src="assets/trans/main.png" alt="" className="main" />
                                    <img src="assets/trans/play.png" alt="" className="play" />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="group_contain_trans">
                                    <div className="headings">
                                        <h5 className="up_tagline text_yellow">WHAT WE DO --</h5>
                                        <h4>Eliminate the IT challenges your business is facing</h4>
                                        <p>Whether you need ongoing IT support or a one-time project, we have the skills and experience to deliver a solution that meets your needs and exceeds your expectations.
                                        </p>


                                        <div className="site_box_card2">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                                            <path d="M48.3491 71.9987C40.1777 72.0667 32.6111 69.4964 25.1957 66.5707C20.5364 64.69 15.9952 62.529 11.597 60.0995C5.36081 56.6976 1.35452 51.5267 0.190432 44.3902C-0.37257 40.4347 0.320148 36.4022 2.1709 32.8615C7.6696 21.3718 16.4021 11.7372 27.2971 5.13957C30.6836 3.02282 34.191 1.11019 38.1292 0.384443C45.8395 -1.04436 52.2571 1.55622 57.2612 7.40752C61.6832 12.5784 64.3364 18.8229 66.6495 25.1202C68.6063 30.3481 70.1481 35.7222 71.2605 41.1924C72.6211 48.1928 72.3036 55.2159 68.6148 61.6644C65.372 67.3418 60.2999 70.3355 53.9881 71.4922C52.1286 71.8172 50.2086 71.8172 48.3491 71.9987Z" fill="#FFF3F1" />
                                                        </svg>
                                                        <img src="assets/vector/ph_user.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="contain">
                                                        <h4>Latest Strategy</h4>
                                                        <p>Through a collaborative and customer-centric approach, we aim to build long-term partnerships with our clients and help them achieve their business objectives.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="site_box_card2">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                                            <path d="M48.3491 71.9987C40.1777 72.0667 32.6111 69.4964 25.1957 66.5707C20.5364 64.69 15.9952 62.529 11.597 60.0995C5.36081 56.6976 1.35452 51.5267 0.190432 44.3902C-0.37257 40.4347 0.320148 36.4022 2.1709 32.8615C7.6696 21.3718 16.4021 11.7372 27.2971 5.13957C30.6836 3.02282 34.191 1.11019 38.1292 0.384443C45.8395 -1.04436 52.2571 1.55622 57.2612 7.40752C61.6832 12.5784 64.3364 18.8229 66.6495 25.1202C68.6063 30.3481 70.1481 35.7222 71.2605 41.1924C72.6211 48.1928 72.3036 55.2159 68.6148 61.6644C65.372 67.3418 60.2999 70.3355 53.9881 71.4922C52.1286 71.8172 50.2086 71.8172 48.3491 71.9987Z" fill="#FFF8EB" />
                                                        </svg>
                                                        <img src="assets/vector/ph_code.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="contain">
                                                        <h4>Latest Strategy</h4>
                                                        <p>Through a collaborative and customer-centric approach, we aim to build long-term partnerships with our clients and help them achieve their business objectives.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
                </section>






                <section className='get_in_touch'>
                    <div className="row container">
                        <div className="col-md-6">

                            <div className="get_in_touch_images">
                                <img className='' src="assets/vector/about.png" alt="" />
                            </div>

                        </div>

                        <div className="col-md-6">
                            <div className="get_in_from">

                                <div className="touch_contain">
                                    <h5 className="up_tagline">Get In Touch --</h5>
                                    <h2 className="sub_heading2">Contact Us To Get Started Winning With IT Today!</h2>
                                    <p className="about-us-hedding">Do you have a project in mind? Get off the ground with our help. Share your information and we’ll get back to you soon.</p>
                                </div>




                                <Gettouch />



                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}

export default Service