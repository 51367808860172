import React from 'react'

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="container">
       <ul className="contact_details">
          <li>work@crocentinternational.com</li>
          <li>|</li>
          <li>+91 98887 47538</li>
        </ul>
      </div>
    </div>
  )
}

export default Topbar