import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Faq from "../common/Faq";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import Gettouch from "../common/Gettouch";
import Shimmer from "react-shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Fade } from "react-reveal";
const MaintenanceSupport = () => {
  return (
    <>
      <Header />
      <Fade bottom>
        <section class=" banner banner-section">
          <div class="container banner-column">
            <img
              class="side_line"
              src="assets/vector/sideline.png"
              alt="banner"
            />

            <img
              class="banner-image2"
              src="assets/vector/maintenance-and-support.png"
              alt="banner"
            />

            <img
              src="assets/vector/egg.png"
              alt=""
              className="bacraound_secle"
            />
            <div class="banner-inner">
              <h1 class="heading-xl2">Maintenance & Support</h1>
              <p class="paragraph2">We can grow your business</p>
            </div>
          </div>
        </section>
      </Fade>
      <main>
        <section className="design_seamless">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="image_design_seamless">
                  <Fade left>
                    <LazyLoadImage
                      src="assets/vector/maintenance-and-support-big.png"
                      className="img1"
                      width={"100%"}
                      height={"100%"}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </Fade>
                  <Fade bottom>
                    <LazyLoadImage
                      src="assets/vector/maintenance-and-support-sm.png"
                      className="img2"
                      width={"100%"}
                      height={"100%"}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </Fade>
                </div>
              </div>
              <div className="col-md-6">
                <div className="level_cointain">
                  <Fade bottom>
                    <h5 className="up_tagline">Maintenance & Support</h5>
                  </Fade>
                  <Fade bottom>
                    <h2 className="hedding">
                      Ensuring Your Online Presence Stays Up and Running
                    </h2>
                  </Fade>
                  <Fade bottom>
                    <p className="graph">
                      Crocent International provides website maintenance, site
                      security, and support services that are essential for the
                      smooth and secure functioning of a website. Businesses
                      should invest in these services to ensure the optimal
                      performance and security of their website. Regular website
                      maintenance helps to improve user experience and site
                      security to prevent data breaches and loss of sensitive
                      information.
                    </p>
                  </Fade>
                  <Fade bottom>
                    <Link to="/contact-us" className="btn_text">
                      {" "}
                      <span className="cercle_left">
                        <ArrowForwardIcon />
                      </span>{" "}
                      Contact Us
                    </Link>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="creative_features">
          <div className="blogbackround">
            {/* <img src="assets/vector/creative_features.png" alt="" /> */}
          </div>
          <div className="container">
            <div className="creative_heading">
              <Fade bottom>
                <h5 className="up_tagline">
                  <span className="treeeLine"></span> BEST PROGRESS{" "}
                  <span className="treeeLine"></span>
                </h5>
              </Fade>
              <Fade bottom>
                <h4>Powerful Content Editing & Publishing Tools</h4>
              </Fade>
              <Fade bottom>
                <p>High-performing, Success-driven scalable CMS solutions</p>
              </Fade>
            </div>

            <div className="creative_features_body creative_features_body2">
              <div className="row">
                <Fade left>
                  <div className="col-md-3">
                    <div className="creative_card creative_card2">
                      <h5>Security updates</h5>
                      <div className="vectors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="3"
                          viewBox="0 0 60 3"
                          fill="none"
                        >
                          <rect width="37" height="3" rx="1.5" fill="#EF5545" />
                          <rect
                            x="49"
                            width="11"
                            height="3"
                            rx="1.5"
                            fill="#EF5545"
                          />
                        </svg>
                        <br />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="67"
                          viewBox="0 0 80 67"
                          fill="none"
                        >
                          <path
                            d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z"
                            fill="#FBDFDC"
                          />
                        </svg>
                      </div>
                      <p>
                      Security patches are released by web development companies to address vulnerabilities in their software.
                      </p>
                    </div>
                  </div>
                </Fade>
        
                <Fade bottom>
                  <div className="col-md-3">
                    <div className="creative_card creative_card2 pos_down_45">
                      <h5>Plugin & Theme updates</h5>
                      <div className="vectors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="3"
                          viewBox="0 0 60 3"
                          fill="none"
                        >
                          <rect width="37" height="3" rx="1.5" fill="#4DBA25" />
                          <rect
                            x="49"
                            width="11"
                            height="3"
                            rx="1.5"
                            fill="#4DBA25"
                          />
                        </svg>
                        <br />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="67"
                          viewBox="0 0 80 67"
                          fill="none"
                        >
                          <path
                            d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z"
                            fill="#EBF4E6"
                          />
                        </svg>
                      </div>
                      <p>
                      Plugins and themes help to extend the functionality of your website. But like any software, they need to be kept up-to-date to ensure compatibility.

                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-md-3">
                    <div className="creative_card creative_card2">
                      <h5>Content updates</h5>
                      <div className="vectors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="3"
                          viewBox="0 0 60 3"
                          fill="none"
                        >
                          <rect width="37" height="3" rx="1.5" fill="#3538B2" />
                          <rect
                            x="49"
                            width="11"
                            height="3"
                            rx="1.5"
                            fill="#3538B2"
                          />
                        </svg>
                        <br />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="67"
                          viewBox="0 0 80 67"
                          fill="none"
                        >
                          <path
                            d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z"
                            fill="#E6EDF6"
                          />
                        </svg>
                      </div>
                      <p>
                      This includes adding new blog posts, updating product descriptions, and removing outdated content.
                      </p>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="col-md-3">
                    <div className="creative_card creative_card2 pos_down_45">
                      <h5>Backup & disaster recovery</h5>
                      <div className="vectors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="3"
                          viewBox="0 0 60 3"
                          fill="none"
                        >
                          <rect width="37" height="3" rx="1.5" fill="#F4AF38" />
                          <rect
                            x="49"
                            width="11"
                            height="3"
                            rx="1.5"
                            fill="#F4AF38"
                          />
                        </svg>
                        <br />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="67"
                          viewBox="0 0 80 67"
                          fill="none"
                        >
                          <path
                            d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z"
                            fill="#FAF5EA"
                          />
                        </svg>
                      </div>
                      <p>
                      A backup helps to prevent data loss and ensures that you can restore your site if it's ever hacked or corrupted.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="get_in_touch2">
          <div className="row container">
            <div className="col-md-6">
              <div className="get_in_touch_images">
                <Fade bottom>
                  <LazyLoadImage
                    className=""
                    src="assets/vector/about.png"
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    effect="blur"
                  />
                </Fade>
              </div>
            </div>

            <div className="col-md-6">
              <div className="get_in_from">
                <div className="touch_contain">
                  <Fade bottom>
                    <h5 className="up_tagline">
                      Get In Touch <span className="treeeLine"></span>
                    </h5>
                  </Fade>
                  <Fade bottom>
                    <h2 className="sub_heading2">
                      Contact Us To Get Started Winning With IT Today!
                    </h2>
                  </Fade>
                  <Fade bottom>
                    <p className="about-us-hedding">
                      Do you have a project in mind? Get off the ground with our
                      help. Share your information and we’ll get back to you
                      soon.
                    </p>
                  </Fade>
                </div>
                <Fade bottom>
                  <Gettouch />
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <Faq />
      </main>
      <Footer />
    </>
  );
};

export default MaintenanceSupport;
