import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Faq from "../common/Faq";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Gettouch from "../common/Gettouch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./ProgressProvider";
import { Margin } from "@mui/icons-material";

const DigitalMarketing = () => {
  const value = 89;
  const value2 = 76;
  const value3 = 63;
  return (
    <>
      <Header />
      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />
          <img
            class="banner-image2"
            src="assets/vector/degital_marketing.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2">Digital Marketing</h1>
            <p class="paragraph2">We can grow your business</p>
          </div>
        </div>
      </section>
      <main>
        <section className="design_seamless">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="image_design_seamless">
                  <img
                    src="assets/vector/degital_big.png"
                    alt=""
                    className="img1"
                  />
                  <img
                    src="assets/vector/degital_sm.png"
                    alt=""
                    className="img2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="level_cointain">
                  <h5 className="up_tagline">Digital Marketing</h5>
                  <h2 className="hedding">
                    Unleash Your Business's Potential with Cutting-Edge Digital
                    Solutions
                  </h2>
                  <p className="graph">
                    We are a team of experienced marketers who specialize in
                    creating effective digital marketing strategies for
                    businesses of all sizes. By leveraging various digital
                    channels such as social media, email, search engines, and
                    mobile applications, we help businesses connect with their
                    target audience and drive more traffic to their website.
                    Invest in top digital marketing solutions and spend
                    effectively so that you get the most return on investment.
                  </p>
                  <Link to="/contact-us" className="btn_text">
                    {" "}
                    <span className="cercle_left">
                      <ArrowForwardIcon />
                    </span>{" "}
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="creative_features">
          <div className="blogbackround2">
            <img src="assets/vector/app_dev_table.png" alt="" />
          </div>
          <div className="container">
            <div className="cdevloment_body">
              <div className="row">
                <div className="col-md-6">
                  <div className="level_cointain ">
                    <h5 className="up_tagline">TECHNOLOGIES</h5>
                    <h2 className="hedding">TECHNOLOGIES WE USE</h2>
                    <p className="graph">
                    We encompass a broad range of channels such as social media, search engines, email, websites, and mobile apps. Our marketing technologies also include advanced analytics and tracking tools that allow businesses to measure the performance of our marketing efforts.

                    </p>
                    <Link to="/contact-us" className="btn_text">
                      {" "}
                      <span className="cercle_left">
                        <ArrowForwardIcon />
                      </span>{" "}
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="table-devloment">
                    <img src="assets/vector/degital_company.png" alt="" />
                    <div className="overlay_company"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="design_responsive">
          <div className="row container">
            <div className="col-md-6">
              <div className="design_responsive_contain">
                <Fade bottom>
                  <h5 className="up_tagline">MARKETING</h5>
                </Fade>
                <Fade bottom>
                  <h2 className="sub_heading2">
                    Marketing to Support Your business
                  </h2>
                </Fade>
                <Fade bottom>
                  <p>
                    Whether it's through social media, email campaigns, or other
                    forms of advertising, investing in marketing supports your
                    business and helps you achieve your goals.
                  </p>
                </Fade>
              </div>

              <div
                className="divProgressProvider"
                style={{
                  width: 500,
                  height: 200,
                  display: "flex",
                  Margin: "10px",
                }}
              >
                <div className="ProgressProvider_text">
                  <ProgressProvider valueStart={0} valueEnd={value}>
                    {(value) => (
                      <CircularProgressbar
                        value={value}
                        text={`${value}%`}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: "butt",
                          textSize: "16px",
                          pathTransitionDuration: 1.5,
                          pathColor: `#0c8ce9`,
                          textColor: "#000",
                          trailColor: "#e9eaec",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    )}
                  </ProgressProvider>
                  <h5>SER Service</h5>
                </div>
                <div className="ProgressProvider_text">
                  <ProgressProvider valueStart={0} valueEnd={value2}>
                    {(value2) => (
                      <CircularProgressbar
                        value={value2}
                        text={`${value2}%`}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: "butt",
                          textSize: "16px",
                          pathTransitionDuration: 1.5,
                          pathColor: `#f1b000`,
                          textColor: "#000",
                          trailColor: "#fdf3d9",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    )}
                  </ProgressProvider>

                  <h5>Copywriting</h5>
                </div>
                <div className="ProgressProvider_text">
                  <ProgressProvider valueStart={0} valueEnd={value3}>
                    {(value3) => (
                      <CircularProgressbar
                        value={value3}
                        text={`${value3}%`}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: "butt",
                          textSize: "16px",
                          pathTransitionDuration: 1.5,
                          pathColor: `#16b4f2`,
                          textColor: "#000",
                          trailColor: "#e7f7fe",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    )}
                  </ProgressProvider>
                  <h5>PPC</h5>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="design_responsive_marketing">
                <div className="marketting_cart mrg_right">
                  <div className=" col-2 market_svg_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="45"
                      viewBox="0 0 44 45"
                      fill="none"
                    >
                      <path
                        d="M29.5466 44.4253C24.5531 44.4664 19.929 42.9135 15.3974 41.1458C12.55 40.0096 9.77486 38.7039 7.08707 37.236C3.27605 35.1806 0.827764 32.0565 0.116375 27.7447C-0.227682 25.3548 0.195646 22.9185 1.32666 20.7792C4.68698 13.8373 10.0235 8.01623 16.6816 4.03006C18.7511 2.75115 20.8945 1.59556 23.3012 1.15708C28.013 0.293815 31.9349 1.86505 34.9929 5.40032C37.6953 8.52451 39.3167 12.2973 40.7302 16.1021C41.9261 19.2607 42.8683 22.5076 43.5481 25.8126C44.3796 30.0422 44.1856 34.2854 41.9313 38.1815C39.9496 41.6117 36.8499 43.4205 32.9927 44.1193C31.8563 44.3157 30.683 44.3157 29.5466 44.4253Z"
                        fill="#3180FC"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="col-10">
                    <h4>SEO Services</h4>
                    <p>
                      If you're looking to command market your online you need
                      comprehensive SEO strategy
                    </p>
                  </div>
                </div>
                <div className="marketting_cart mrg_center">
                  <div className=" col-2 market_svg_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="45"
                      viewBox="0 0 44 45"
                      fill="none"
                    >
                      <path
                        d="M29.5466 44.4253C24.5531 44.4664 19.929 42.9135 15.3974 41.1458C12.55 40.0096 9.77486 38.7039 7.08707 37.236C3.27605 35.1806 0.827764 32.0565 0.116375 27.7447C-0.227682 25.3548 0.195646 22.9185 1.32666 20.7792C4.68698 13.8373 10.0235 8.01623 16.6816 4.03006C18.7511 2.75115 20.8945 1.59556 23.3012 1.15708C28.013 0.293815 31.9349 1.86505 34.9929 5.40032C37.6953 8.52451 39.3167 12.2973 40.7302 16.1021C41.9261 19.2607 42.8683 22.5076 43.5481 25.8126C44.3796 30.0422 44.1856 34.2854 41.9313 38.1815C39.9496 41.6117 36.8499 43.4205 32.9927 44.1193C31.8563 44.3157 30.683 44.3157 29.5466 44.4253Z"
                        fill="#f1b000"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="col-10">
                    <h4>Copywriting</h4>
                    <p>
                      Amplify your brand and control the conversation with a
                      strategic content marketing strategy
                    </p>
                  </div>
                </div>
                <div className="marketting_cart mrg_left">
                  <div className=" col-2 market_svg_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="45"
                      viewBox="0 0 44 45"
                      fill="none"
                    >
                      <path
                        d="M29.5466 44.4253C24.5531 44.4664 19.929 42.9135 15.3974 41.1458C12.55 40.0096 9.77486 38.7039 7.08707 37.236C3.27605 35.1806 0.827764 32.0565 0.116375 27.7447C-0.227682 25.3548 0.195646 22.9185 1.32666 20.7792C4.68698 13.8373 10.0235 8.01623 16.6816 4.03006C18.7511 2.75115 20.8945 1.59556 23.3012 1.15708C28.013 0.293815 31.9349 1.86505 34.9929 5.40032C37.6953 8.52451 39.3167 12.2973 40.7302 16.1021C41.9261 19.2607 42.8683 22.5076 43.5481 25.8126C44.3796 30.0422 44.1856 34.2854 41.9313 38.1815C39.9496 41.6117 36.8499 43.4205 32.9927 44.1193C31.8563 44.3157 30.683 44.3157 29.5466 44.4253Z"
                        fill="#16b4f2"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                      <path
                        d="M18.6 26.4799L14.4 22.3275L13 23.7116L18.6 29.2481L30.6 17.3841L29.2 16L18.6 26.4799Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="col-10">
                    <h4>Pay Per Click</h4>
                    <p>
                      PPC management is all about delivering the right ad to
                      your future customers at the exact
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

   


        <section className="marketing_features">
          <div className="blogbackround2">
            <img src="assets/vector/degital_marketingbg.png" alt="" />
          </div>
          <div className="container">
          <div className="narkettinf_c_heading">
              <Fade bottom>
                <h5 className="up_tagline">
                  <span className="treeeLine"></span> BEST PROGRESS{" "}
                  <span className="treeeLine"></span>
                </h5>
              </Fade>
              <Fade bottom>
                <h4>Creative Features & Support</h4>
              </Fade>
              <Fade bottom>
                <p>
                  Bringing the best to our Customers & Making Them the Happiest
                </p>
              </Fade>
            </div>

            <div className="degital_marketing_dashboard">
              <div className="row">
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard1.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard2.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard3.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mark_dashboard">
                    <img src="assets/vector/dashboard4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="get_in_touch2">
          <div className="row container">
            <div className="col-md-6">
              <div className="get_in_touch_images">
                <Fade bottom>
                  <LazyLoadImage
                    className=""
                    src="assets/vector/about.png"
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    effect="blur"
                  />
                </Fade>
              </div>
            </div>

            <div className="col-md-6">
              <div className="get_in_from">
                <div className="touch_contain">
                  <Fade bottom>
                    <h5 className="up_tagline">
                      Get In Touch <span className="treeeLine"></span>
                    </h5>
                  </Fade>
                  <Fade bottom>
                    <h2 className="sub_heading2">
                      Contact Us To Get Started Winning With IT Today!
                    </h2>
                  </Fade>
                  <Fade bottom>
                    <p className="about-us-hedding">
                      Do you have a project in mind? Get off the ground with our
                      help. Share your information and we’ll get back to you
                      soon.
                    </p>
                  </Fade>
                </div>
                <Fade bottom>
                  <Gettouch />
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <Faq />
      </main>
      <Footer />
    </>
  );
};

export default DigitalMarketing;
