import React from 'react'
import '../Style/main.css';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Gettouch from '../common/Gettouch';
import {GoLocation} from 'react-icons/go';
import {AiOutlineMail} from 'react-icons/ai';
import {BiPhoneCall} from 'react-icons/bi';
const Contact = () => {
  return (
    <>
      <Header />

      <section class=" banner banner-section">
        <div class="container banner-column">
          <img class="side_line" src="assets/vector/sideline.png" alt="banner" />
          <img class="banner-image2" src="assets/vector/contact.png" alt="banner" />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2">Contact Us</h1>
            <p class="paragraph2">We can grow your business</p>

          </div>
        </div>
      </section>
      <main>

        <section className='get_in_touch2'>
          <div className="row container">
            <div className="col-md-6">

              <div className="get_in_touch_images">
                <img className='' src="assets/vector/about.png" alt="" />
              </div>

            </div>

            <div className="col-md-6">
              <div className="get_in_from">

                <div className="touch_contain">
                  <h5 className="up_tagline">Get In Touch --</h5>
                  <h2 className="sub_heading2">Contact Us To Get Started Winning With IT Today!</h2>
                  <p className="about-us-hedding">Do you have a project in mind? Get off the ground with our help. Share your information and we’ll get back to you soon.</p>
                </div>




                <Gettouch />



              </div>

            </div>
          </div>
        </section>

        <section className='contact_box'>

          <div className="container">
            <div className="row">


              <div className="col-md-4">
                <div className="conttact_card">
                  <div className="hedding hedding1">
                    <GoLocation/>
                    <h2>Office Address</h2>
                  </div>

                  <div className="buttom_contact">
                    <img src="assets/vector/con_box1.png" alt="" />
                    <p>Office No - 101, Plot No: E-299, Industrial Area Phase 8A , Sector 75, SAS Nagar, Punjab 160075</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="conttact_card">
                  <div className="hedding hedding2">
                    <AiOutlineMail/>
                    <h2>Mail Us</h2>
                  </div>

                  <div className="buttom_contact">
                    <img src="assets/vector/con_box2.png" alt="" />
                    <p>work@crocentinternational.com</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="conttact_card">
                  <div className="hedding hedding3">
                    <BiPhoneCall/>
                    <h2>Contact Now</h2>
                  </div>

                  <div className="buttom_contact">
                    <img src="assets/vector/con_box3.png" alt="" />
                    <p>+91 98887 47538</p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </section>



      </main>
      <Footer />



    </>
  )
}

export default Contact