import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import reviewsData from "../DataRatting.json";
export const Testimonials = () => {
  return (
    <>
      <Header />
      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />
          <img
            class="banner-image2"
            src="assets/vector/testimonials.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2">Testimonials</h1>
            <p class="paragraph2">We can grow your business</p>
          </div>
        </div>
      </section>
      <main>
        <section className="testimonial_body">
          <div className="container">
            <div className="review_heading">
              <h5 className="up_tagline">-- TESTIMONIALS --</h5>
              <h4>WHAT OUR CLIENT’S SAY ABOUT US</h4>
              <p>
                Check out our clients testimonials to hear directly from the
                marketers and business owners who’ve worked with us.
              </p>
            </div>

            <div className="review_bo
            dy">
              <div className="row">
                {reviewsData.map((review, index) => (
                  <div key={index} className="col-md-6">
                  <div className="top_review_top2">
                    <div className="top_review_box2">
                      <div className="head">
                        <img src={review.images} alt="" />
                        <div className="div">
                          <h2>{review.name}</h2>
                          {/* <a href="">{review.role}</a> */}
                        </div>
                      </div>
                      <p>{review.content}</p>
                    </div>
                  </div>
                  </div>
                ))}

              </div>


              <div className="review_more">
                <a href="" className="btn_text">
                  {" "}
                  <span className="cercle_left">
                    <ArrowForwardIcon />
                  </span>{" "}
                  View More
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
