import React, {useEffect} from 'react'
import '../Style/main.css';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Faq from '../common/Faq';
import CompaniLogo from '../common/CompaniLogo';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
const About = () => {
    useEffect(() => {
        document.title = "About - Crocent International";
      }, []);
    return (
        <>
            <Header />
            <section class=" banner banner-section">
                <div class="container banner-column">
                    <img class="side_line" src="assets/vector/sideline.png" alt="banner" />
                    <img class="banner-image2" src="assets/vector/about-us.png" alt="banner" />
                    <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
                    <div class="banner-inner">
                        <h1 class="heading-xl2"> About Us</h1>
                        <p class="paragraph2">We can grow your business</p>

                    </div>
                </div>
            </section>
            <main>
                <section className="business_growth">
                    <div className="container">
                        <div className="heading_growth">
                            <h5 className="up_tagline">Who We Are--</h5>
                            <h2>What We Can Do For Your Business Growth</h2>
                            <p>We are profoundly dedicated to provide business solutions that strictly meet the business needs. Using a proactive approach and agile process, we make sure your time and investment are optimised at every step of working.</p>
                        </div>

                        <div className="business_growth_body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="growth_caed">
                                        <h5>Creative Ideas</h5>
                                        <div className="vectors">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="3" viewBox="0 0 60 3" fill="none">
                                                <rect width="37" height="3" rx="1.5" fill="#EF5545" />
                                                <rect x="49" width="11" height="3" rx="1.5" fill="#EF5545" />
                                            </svg>
                                            
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67" viewBox="0 0 80 67" fill="none">
                                                <path d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z" fill="#FBDFDC" />
                                            </svg>
                                        </div>
                                        <p>Continuously strive to innovate and offer new and unique solutions for your business.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="growth_caed">
                                        <h5>Awsome Design</h5>
                                        <div className="vectors">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="3" viewBox="0 0 60 3" fill="none">
                                                <rect width="37" height="3" rx="1.5" fill="#4DBA25" />
                                                <rect x="49" width="11" height="3" rx="1.5" fill="#4DBA25" />
                                            </svg>
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67" viewBox="0 0 80 67" fill="none">
                                                <path d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z" fill="#EBF4E6" />
                                            </svg>
                                        </div>
                                        <p>Offer clients clean and minimalistic designs for their websites or marketing materials to make them more engaging and memorable.</p>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="growth_caed">
                                        <h5>Best Features</h5>
                                        <div className="vectors">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="3" viewBox="0 0 60 3" fill="none">
                                                <rect width="37" height="3" rx="1.5" fill="#3538B2" />
                                                <rect x="49" width="11" height="3" rx="1.5" fill="#3538B2" />
                                            </svg>
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67" viewBox="0 0 80 67" fill="none">
                                                <path d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z" fill="#E6EDF6" />
                                            </svg>
                                        </div>
                                        <p>Customization, Communication, Expertise, Reliability and Value of working with your business.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="growth_caed">
                                        <h5>Easy Solutions</h5>
                                        <div className="vectors">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="3" viewBox="0 0 60 3" fill="none">
                                                <rect width="37" height="3" rx="1.5" fill="#F4AF38" />
                                                <rect x="49" width="11" height="3" rx="1.5" fill="#F4AF38" />
                                            </svg>
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67" viewBox="0 0 80 67" fill="none">
                                                <path d="M79.0058 31.6785C77.4058 37.2785 56.0058 37.6785 45.5058 37.1785C39.9058 38.7785 37.1725 45.8451 36.5059 49.1785C34.5059 61.9785 26.6725 65.8451 23.0059 66.1785C6.20586 68.9785 0.672516 46.0118 0.0058441 34.1785C-0.394156 8.17847 19.8392 0.678466 30.0058 0.178465C63.2058 -2.22153 76.5058 20.1785 79.0058 31.6785Z" fill="#FAF5EA" />
                                            </svg>
                                        </div>
                                        <p>We take into consideration the brand identity, industry standards, and accordingly provide easy solutions for growth. </p>
                                    </div>
                                </div>

                            </div>
                        </div>




                    </div>
                </section>
                <section className="take_your_business">
                    <div className="container">

                        <div className="about_site_images">
                            <img src="assets/vector/about_site.png" alt="" />
                        </div>


                        <div className="business_heading">
                            <h5 className="up_tagline">-- About Us --</h5>
                            <h4>We Can Take Your Business To The Next Level</h4>
                            <p>Crocent Web International is a trusted IT Solutions Provider,with a specialized expertise and knowledge in the field of information technology. We have a deep understanding of the latest technologies, trends, and best practices in the industry. </p>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mage_group">
                                    <img src="assets/trans/tp.png" alt="" className="pert_top" />
                                    <img src="assets/trans/bt.png" alt="" className="pert_btm" />
                                    <img src="assets/vector/persons.png" alt="" className="main" />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="group_contain_trans">
                                    <div className="headings">

                                        <p>We help businesses choose the right technology solutions and provide guidance on how to implement them.
                                        </p>

                                        <div className="site_box_card">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                                            <path d="M48.3491 71.9987C40.1777 72.0667 32.6111 69.4964 25.1957 66.5707C20.5364 64.69 15.9952 62.529 11.597 60.0995C5.36081 56.6976 1.35452 51.5267 0.190432 44.3902C-0.37257 40.4347 0.320148 36.4022 2.1709 32.8615C7.6696 21.3718 16.4021 11.7372 27.2971 5.13957C30.6836 3.02282 34.191 1.11019 38.1292 0.384443C45.8395 -1.04436 52.2571 1.55622 57.2612 7.40752C61.6832 12.5784 64.3364 18.8229 66.6495 25.1202C68.6063 30.3481 70.1481 35.7222 71.2605 41.1924C72.6211 48.1928 72.3036 55.2159 68.6148 61.6644C65.372 67.3418 60.2999 70.3355 53.9881 71.4922C52.1286 71.8172 50.2086 71.8172 48.3491 71.9987Z" fill="#FFF3F1" />
                                                        </svg>
                                                        <img src="assets/vector/mission.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="contain">
                                                        <h4>Our Mission & Vision</h4>
                                                        <p>We are committed to providing innovative and effective IT strategies that connect businesses with their customers and drive measurable results.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="site_box_card">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                                            <path d="M48.3491 71.9987C40.1777 72.0667 32.6111 69.4964 25.1957 66.5707C20.5364 64.69 15.9952 62.529 11.597 60.0995C5.36081 56.6976 1.35452 51.5267 0.190432 44.3902C-0.37257 40.4347 0.320148 36.4022 2.1709 32.8615C7.6696 21.3718 16.4021 11.7372 27.2971 5.13957C30.6836 3.02282 34.191 1.11019 38.1292 0.384443C45.8395 -1.04436 52.2571 1.55622 57.2612 7.40752C61.6832 12.5784 64.3364 18.8229 66.6495 25.1202C68.6063 30.3481 70.1481 35.7222 71.2605 41.1924C72.6211 48.1928 72.3036 55.2159 68.6148 61.6644C65.372 67.3418 60.2999 70.3355 53.9881 71.4922C52.1286 71.8172 50.2086 71.8172 48.3491 71.9987Z" fill="#FFF8EB" />
                                                        </svg>
                                                        <img src="assets/vector/strategy.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="contain">
                                                        <h4>Latest Strategy</h4>
                                                        <p>Through a collaborative and customer-centric approach, we aim to build long-term partnerships with our clients and help them achieve their business objectives.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <CompaniLogo />
                    </div>
                </section>
                <section className="about_teams">
                    <div className="container">
                        <div className="business_team_headding">
                            <h5 className="up_tagline">-- About Us --</h5>
                            <h4>Our Outstand expert Team Members</h4>
                            <p>Meet our team of professional marketers, designers, and developers, and we know what it takes to get real results online.</p>
                        </div>

                        <div className="team_Avout_body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="team_card">
                                        <div className="person">
                                            <img src="assets/team/person.png" alt="" />
                                        </div>
                                        <div className="details_card">
                                            <h5>Akshit Aneja</h5>
                                            <p>Founder & MD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="team_card">
                                        <div className="person">
                                            <img src="assets/team/person.png" alt="" />
                                        </div>
                                        <div className="details_card">
                                            <h5>Akshit Aneja</h5>
                                            <p>Founder & MD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="team_card">
                                        <div className="person">
                                            <img src="assets/team/person.png" alt="" />
                                        </div>
                                        <div className="details_card">
                                            <h5>Akshit Aneja</h5>
                                            <p>Founder & MD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="learn_more">
                                <Link to="/our-team" className='btn_text'> <span className='cercle_left'><ArrowForwardIcon /></span> Get Start</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Faq />
            </main>
            <Footer />
        </>
    )
}

export default About