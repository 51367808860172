import React from "react";
import "../Style/main.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "../common/Header";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Carousel from "react-multi-carousel";
import Footer from "../common/Footer";
import CompaniLogo from "../common/CompaniLogo";
import Gettouch from "../common/Gettouch";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Shimmer } from "react-shimmer";
const Home = () => {


  const [data, setData] = useState(null);
  // React.useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  useEffect(() => {
    document.title = "Home - Crocent International";
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.crocentinternational.com/blog/recent_blog/crocentwebservices.com"
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 692 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 692, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };



  return (
    <>
      <Header />
      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />

          <img
            class="banner-image"
            src="assets/vector/home_hero.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <Fade left>
              <p className="tag">EMPOWER YOUR BUSINESS</p>
            </Fade>
            <Fade left>
              <h1 className="heading-xl">
                Get World Class{" "}
                <span className="text_yellow"> Best Solution</span> From Us
              </h1>
            </Fade>
            <Fade left>
              <p className="paragraph">
                We're a team of design, programming, and marketing specialists,
                providing quality services that generate real-world results.
              </p>
            </Fade>
            <Fade left>
              <a href="" className="btn_text">
                {" "}
                <span className="cercle_left">
                  <ArrowForwardIcon />
                </span>{" "}
                Get Start
              </a>
            </Fade>
          </div>
        </div>
      </section>

      <main>
        <section className="next_level">
          <div className="container">
            <div className="row">
              <div className="col-md-6 class-order">
                <div className="image_container_level">
                  <img src="assets/vector/level1.png" alt="" className="img1" />
                  <img src="assets/vector/level2.png" alt="" className="img2" />
                  <img src="assets/vector/level3.png" alt="" className="img3" />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="level_cointain">
                  <h5 className="up_tagline">About Us --</h5>
                  <h2 className="hedding">
                    We Can Take Your Business To The Next Level
                  </h2>
                  <p className="graph">
                    Crocent Web Services is a trusted IT Solutions Provider,with
                    a specialised expertise and knowledge in the field of
                    information technology. We have a deep understanding of the
                    latest technologies, trends, and best practices in the
                    industry.  We help businesses choose the right technology
                    solutions and provide guidance on how to implement them.
                  </p>
                  <a href="" className="btn_text">
                    {" "}
                    <span className="cercle_left">
                      <ArrowForwardIcon />
                    </span>{" "}
                    Get Start
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="busness_section">
          <div className="container">
            <img
              src="assets/vector/overbg.png"
              alt=""
              className="overbackround"
            />

            <div className="busness_soluation">
              <div className="heading">
                <div className="text">
                  <h5 className="">Services --</h5>
                  <h4>
                    We provide IT &<br />
                    Business solutions
                  </h4>
                  <a href="" className="btn_text">
                    {" "}
                    <span className="cercle_left">
                      <ArrowForwardIcon />
                    </span>{" "}
                    Get Start
                  </a>
                </div>

                <div className="tagline">
                  <p>
                    We look forward to completing the business requirements with
                    our cost-effective solutions <br /> and resources that they
                    may not be able to afford on their own.
                  </p>
                </div>
              </div>

              <div className="contains">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-9">
                    <Carousel showDots="true" responsive={responsive}>
                      <div className="cart_Busness">
                        <svg
                          className="rolls"
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            d="M53.7212 79.9985C44.6419 80.0741 36.2346 77.2182 27.9952 73.9675C22.8183 71.8778 17.7725 69.4767 12.8856 66.7772C5.95646 62.9973 1.50503 57.2519 0.211591 49.3224C-0.413967 44.9274 0.35572 40.4469 2.41211 36.5127C8.52178 23.7464 18.2246 13.0413 30.3302 5.71064C34.0929 3.35869 37.99 1.23354 42.3658 0.427159C50.9327 -1.1604 58.0634 1.72913 63.6235 8.23058C68.5369 13.976 71.4849 20.9143 74.055 27.9113C76.2292 33.7201 77.9423 39.6913 79.1783 45.7693C80.6901 53.5475 80.3374 61.351 76.2387 68.516C72.6356 74.8242 66.9999 78.1506 59.9868 79.4357C57.9206 79.7969 55.7873 79.7969 53.7212 79.9985Z"
                            fill="#FFF6EE"
                          />
                        </svg>
                        <img src="assets/vector/web.png" alt="" />
                        <div className="body_bus">
                          <Link to="/web-design">
                            <h5>Web Design</h5>
                          </Link>
                          <p>
                            Our agile and cross functioning teams build, design,
                            create, and maintain mobile applications...
                          </p>
                          <Link to="/web-design" className="cercle_left">
                            <ArrowForwardIcon />
                          </Link>
                        </div>
                      </div>

                      <div className="cart_Busness">
                      <svg
                          className="rolls"
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            d="M53.7212 79.9985C44.6419 80.0741 36.2346 77.2182 27.9952 73.9675C22.8183 71.8778 17.7725 69.4767 12.8856 66.7772C5.95646 62.9973 1.50503 57.2519 0.211591 49.3224C-0.413967 44.9274 0.35572 40.4469 2.41211 36.5127C8.52178 23.7464 18.2246 13.0413 30.3302 5.71064C34.0929 3.35869 37.99 1.23354 42.3658 0.427159C50.9327 -1.1604 58.0634 1.72913 63.6235 8.23058C68.5369 13.976 71.4849 20.9143 74.055 27.9113C76.2292 33.7201 77.9423 39.6913 79.1783 45.7693C80.6901 53.5475 80.3374 61.351 76.2387 68.516C72.6356 74.8242 66.9999 78.1506 59.9868 79.4357C57.9206 79.7969 55.7873 79.7969 53.7212 79.9985Z"
                            fill="#F0F9FF"
                          />
                        </svg>
                        <img src="assets/vector/app_dev_logo.png" alt="" />
                        <div className="body_bus">
                          <Link to="application-development"><h5>APPLICATIONS DEVELOPMENT</h5></Link>
                          <p>
                            Our agile and cross functioning teams build, design,
                            create, and maintain mobile applications...
                          </p>
                          <Link to="application-development" className="cercle_left">
                            <ArrowForwardIcon />
                          </Link>
                        </div>
                      </div>


                      <div className="cart_Busness">
                      <svg
                          className="rolls"
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            d="M53.7212 79.9985C44.6419 80.0741 36.2346 77.2182 27.9952 73.9675C22.8183 71.8778 17.7725 69.4767 12.8856 66.7772C5.95646 62.9973 1.50503 57.2519 0.211591 49.3224C-0.413967 44.9274 0.35572 40.4469 2.41211 36.5127C8.52178 23.7464 18.2246 13.0413 30.3302 5.71064C34.0929 3.35869 37.99 1.23354 42.3658 0.427159C50.9327 -1.1604 58.0634 1.72913 63.6235 8.23058C68.5369 13.976 71.4849 20.9143 74.055 27.9113C76.2292 33.7201 77.9423 39.6913 79.1783 45.7693C80.6901 53.5475 80.3374 61.351 76.2387 68.516C72.6356 74.8242 66.9999 78.1506 59.9868 79.4357C57.9206 79.7969 55.7873 79.7969 53.7212 79.9985Z"
                            fill="#F5F0F7"
                          />
                        </svg>
                        <img src="assets/vector/dm_logo.png" alt="" />
                        <div className="body_bus">
                          <Link to="digital-marketing"><h5>DIGITAL MARKETING</h5></Link>
                          <p>
                            Online marketing to meet the needs and goals of your
                            customers and get the best...
                          </p>
                          <Link to="digital-marketing" className="cercle_left">
                            <ArrowForwardIcon />
                          </Link>
                        </div>
                      </div>
                      <div className="cart_Busness">
                      <svg
                          className="rolls"
                          xmlns="http://www.w3.org/2000/svg"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            d="M53.7212 79.9985C44.6419 80.0741 36.2346 77.2182 27.9952 73.9675C22.8183 71.8778 17.7725 69.4767 12.8856 66.7772C5.95646 62.9973 1.50503 57.2519 0.211591 49.3224C-0.413967 44.9274 0.35572 40.4469 2.41211 36.5127C8.52178 23.7464 18.2246 13.0413 30.3302 5.71064C34.0929 3.35869 37.99 1.23354 42.3658 0.427159C50.9327 -1.1604 58.0634 1.72913 63.6235 8.23058C68.5369 13.976 71.4849 20.9143 74.055 27.9113C76.2292 33.7201 77.9423 39.6913 79.1783 45.7693C80.6901 53.5475 80.3374 61.351 76.2387 68.516C72.6356 74.8242 66.9999 78.1506 59.9868 79.4357C57.9206 79.7969 55.7873 79.7969 53.7212 79.9985Z"
                            fill="#F3F3F6"
                          />
                        </svg>
                        <img src="assets/vector/hosting_logo.png" alt="" />
                        <div className="body_bus">
                          <h5>HOSTING</h5>
                          <p>
                            Web hosting provider to host websites and make them
                            accessible over the internet...
                          </p>
                          <Link to="/" className="cercle_left">
                            <ArrowForwardIcon />
                          </Link>
                        </div>
                      </div>
                  
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>

            <div className="download_app">
              <div className="heading">
                <h5 className="up_tagline text_yellow">-- Our Apps --</h5>
                <h4>Download Our APPS</h4>
                <p>
                  Get the latest tips and tricks in technology and marketing to
                  learn the best practices <br />
                  you can put into action. 
                </p>
              </div>

              <div className="sectionimage">
                <div className="row">
                  <div className="col-md-6">
                    <div className="contain_all_dwonloadapp">
                      <h5 className="h5">
                        <span>GET OUR APP </span> <br />
                        CROCENT ASTRO
                      </h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley.
                      </p>
                      <div className="app_store">
                        <img src="assets/phone/play.png" alt="" />
                        <img src="assets/phone/apple.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="image_container">
                      <div className="phone_sec">
                        <img
                          src="assets/phone/pn.png"
                          alt=""
                          className="phone"
                        />
                        <img
                          src="assets/phone/cercle12.png"
                          alt=""
                          className="phone_bg"
                        />
                        <img
                          src="assets/phone/cercle3.png"
                          alt=""
                          className="phone_top"
                        />

                        <img
                          src="assets/phone/cercle1.png"
                          alt=""
                          className="phone_left"
                        />
                        <img
                          src="assets/phone/cercle3.png"
                          alt=""
                          className="phone_right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="transform">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="mage_group">
                  <img src="assets/trans/tp.png" alt="" className="pert_top" />
                  <img src="assets/trans/bt.png" alt="" className="pert_btm" />
                  <img src="assets/trans/main.png" alt="" className="main" />
                  <img src="assets/trans/play.png" alt="" className="play" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="group_contain_trans">
                  <div className="headings">
                    <h5 className="up_tagline text_yellow">features --</h5>
                    <h4>We transform every dream to reality</h4>
                    <p>
                      Our proficient team has years of experience in crafting
                      visually appealing websites, apps and accelerating brand
                      presence, optimising conversion metrics and much more. Our
                      extensive portfolio encompasses hundreds of successful
                      clients converting their ideas into real time working
                      applications.
                    </p>

                    <div className="client_numbers">
                      <div className="box">
                        <h6>125+</h6>
                        <p>Clients</p>
                      </div>
                      <div className="box">
                        <h6>125+</h6>
                        <p>Clients</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CompaniLogo />
          </div>
        </section>

        <section className="testimonials">
          <div className="right_particle">
            <img src="assets/review/left.png" alt="" />
          </div>
          <div className="left_particle">
            <img src="assets/review/right.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="testimonials_hedding">
                  <h5 className="up_tagline">TESTIMONIALS --</h5>

                  <h4>WHAT OUR CLIENT’S SAY ABOUT US</h4>
                  <p className="tagline">
                    Check out our clients testimonials to hear directly from the
                    marketers and business owners who’ve worked with us.
                  </p>
                </div> 
              </div>
              <div className="col-md-8">
                <div className="review_body_contain">
                  <div className="backround">
                    <img
                      className="top_quate"
                      src="assets/vector/top_quate.png"
                      alt=""
                    />
                    <img
                      src="assets/review/squre.png"
                      alt=""
                      className="review_backround"
                    />
                  </div>
                  <div className="top_review_top">
                    <div className="top_review_box">
                      <div className="head">
                        <img src="assets/review/kristin-watson.png" alt="" />
                        <div className="div">
                          <h2>Kristin Watson</h2>
                      
                        </div>
                      </div>
                      <p>
                      Crocent has the best technology team and delivered results. Very happy to be associated with them.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="top_review_buttom">
                    <div className="top_review_box">
                      <div className="head">
                        <img src="assets/review/lisa-watts.png" alt="" />
                        <div className="div">
                          <h2>Lisa Watts</h2>
                    
                        </div>
                      </div>
                      <p>
                      We were struggling with outdated systems and limited IT resources until we found Crocent International. Their comprehensive IT services have revitalized our business, enabling us to stay ahead of the competition.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our_blog">
          <div className="blogbackround">
            <img src="assets/blogs/blogbg.png" alt="" />
          </div>
          <div className="container">
            <div className="Home_blog_heading">
              <h5 className="up_tagline">-- Our Blog --</h5>
              <h4>Latest News & Articles Directly from Blog</h4>
              <p>
                Get the latest tips and tricks in technology and marketing to
                learn the best practices you can put into action. 
              </p>
            </div>

            <div className="home_all_blogs">
          
              {data == null ? (
                <div className="row mb-5">
                  <div className="col-md-6">
                    <Fade top>
                      <div className="article-blog2">
                        <div className="shimmer-placeholder">
                          <Shimmer width={"100%"} height={300} />
                        </div>
                        <div className="article_footer shadow-sm">
                          <Shimmer width={"50%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <a href="" className="read_more">
                            <Shimmer width={"20%"} height={16} />
                          </a>
                          <br />
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade top>
                      <div className="article-blog2">
                        <div className="shimmer-placeholder">
                          <Shimmer width={"100%"} height={300} />
                        </div>
                        <div className="article_footer shadow-sm">
                          <Shimmer width={"50%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <Shimmer width={"100%"} height={16} />
                          <br />
                          <a href="" className="read_more">
                            <Shimmer width={"20%"} height={16} />
                          </a>
                          <br />
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              ) :(
                (
                  <Carousel responsive={responsive2}>
                    {data.map((item, index) => (
                  
                        <div className="blog_card mb-5">
                          {/* <img src="assets/blogs/1.png" alt="" /> */}
  
                          <LazyLoadImage
                            src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                            width={"100%"}
                            height={"100%"}
                            alt="Image Alt"
                            effect="blur"
                          />
  
                          <div className="contain">
                            <h6>{item.post_date}</h6>
                            <Link to={`blog/${item.url}`}><h3>{item.blog_title}</h3></Link>
                            <p dangerouslySetInnerHTML={{ __html: item.desc }}>                   
                            </p>
                            <Link to={`blog/${item.url}`} className="btn_text">
                              {" "}
                              <span className="cercle_left">
                                <ArrowForwardIcon />
                              </span>{" "}
                              Read More
                            </Link>
                          </div>
                        </div>
                  
                    ))}
                    </Carousel>
             
              ))
            }
                {/* <div className="blog_card">
                  <img src="assets/blogs/1.png" alt="" />
                  <div className="contain">
                    <h6>August 12,2022 - John Doe</h6>
                    <h3>Questions every business owner able to answer</h3>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when its
                      layout...
                    </p>
                    <a href="" className="btn_text">
                      {" "}
                      <span className="cercle_left">
                        <ArrowForwardIcon />
                      </span>{" "}
                      Read More
                    </a>
                  </div>
                </div> */}
          
              
            </div>
          </div>
        </section>

        <section className="get_in_touch">
          <div className="row container">
            <div className="col-md-6">
              <div className="get_in_touch_images">
                <img className="" src="assets/vector/about.png" alt="" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="get_in_from">
                <div className="touch_contain">
                  <h5 className="up_tagline">Get In Touch --</h5>
                  <h2 className="sub_heading2">
                    Contact Us To Get Started Winning With IT Today!
                  </h2>
                  <p className="about-us-hedding">
                    Do you have a project in mind? Get off the ground with our
                    help. Share your information and we’ll get back to you soon.
                  </p>
                </div>

                <Gettouch />
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Home;
