import React from "react";
import "../Style/main.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Collapse } from "antd";
import Faq from "../common/Faq";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import data from "../team.json";
const Team = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { Panel } = Collapse;
  const [jsonData, setJsonData] = useState([]);
  const [initialItemCount, setInitialItemCount] = useState(4);
  const [itemsPerLoad, setItemsPerLoad] = useState(4);
  const memberDataRef = useRef(null);

  useEffect(() => {
    // Load initial data from the JSON file
    setJsonData(data.slice(0, initialItemCount));
  }, [initialItemCount]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nextIndex = jsonData.length + itemsPerLoad;
        setJsonData(data.slice(0, nextIndex));
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "200px",
      threshold: 0,
    });

    const memberDataDiv = memberDataRef.current;
    if (memberDataDiv) {
      observer.observe(memberDataDiv);
    }

    return () => {
      if (memberDataDiv) {
        observer.unobserve(memberDataDiv);
      }
    };
  }, [jsonData, itemsPerLoad]);

  return (
    <>
      <Header />
      <section class=" banner banner-section">
        <div class="container banner-column">
          <img
            class="side_line"
            src="assets/vector/sideline.png"
            alt="banner"
          />
          <img
            class="banner-image2"
            src="assets/vector/team.png"
            alt="banner"
          />
          <img src="assets/vector/egg.png" alt="" className="bacraound_secle" />
          <div class="banner-inner">
            <h1 class="heading-xl2">Our Team</h1>
            <p class="paragraph2">We can grow your business</p>
          </div>
        </div>
      </section>
      <main>
        <section className="our_team_body">
          <div className="container">
            <div className="review_heading">
              <h5 className="up_tagline">-- Our Team --</h5>
              <h4>Our Outstand expert Team Members</h4>
              <p>
                Meet our team of professional marketers, designers, and
                developers, and we know what it takes to get real results
                online. 
              </p>
            </div>

            <div className="team_body">
              <div className="container">
                <div
                  className="row pt-5"
                  ref={memberDataRef}
                  style={{ overflow: "auto" }}
                >
                  {jsonData.map((item) => (
                    <div className="col-md-3">
                      <div className="team_card">
                        <div className="person">
                        <img src={item.image} alt="" />
                        </div>
                        <div className="details_card">
                          <h5>{item.name}</h5>
                          <p>{item.type}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Faq />
      </main>
      <Footer />
    </>
  );
};

export default Team;
